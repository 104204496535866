import axios from 'axios';
import store from '@/store'

class ContentmanagerServiceClass {

  // PROFILES
  async createProfile({project_id, language_id = 1, title}) {
    try {
      const {data} = await axios.post('api/content/addProfile/', {project_id, language_id, title})
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async updateProfile(profile) {
    try {
      const {data} = await axios.put('api/content/updateProfile/', profile)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async deleteProfile(profile_id) {
    try {
      const {data} = await axios.delete(`api/content/deleteProfile/${profile_id}`)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  // PARTITIONS
  async createPartition(previousPartitionSeq, project_id) {
    try {
      const {data} = await axios.post('api/content/addPartition/', {previousPartitionSeq, project_id})
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async deletePartition(partition_id) {
    try {
      const {data} = await axios.delete(`api/content/deletePartition/${partition_id}`)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  // CHUNKS
  async createChunk(previousChunkSeq, project_id, partition_id) {
    try {
      const {data} = await axios.post('api/content/addChunk', {previousChunkSeq, project_id, partition_id})
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async deleteChunk(chunk_id) {
    try {
      const {data} = await axios.delete(`api/content/deleteChunk/${chunk_id}`)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }

  async saveProject(project) {
    try {
      const {data} = await axios.put(`api/content/update/${project.id}`, project)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }


  async loadProject(project_id) {
    try {
      const {data} = await axios.get(`api/content/${project_id}`)
      console.log(data)
      return data
    } catch (e) {
      await Promise.reject(e)
    }
  }


}

const contentmanagerService = new ContentmanagerServiceClass()
export default contentmanagerService
