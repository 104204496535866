<template>
  <v-expansion-panel>

    <v-expansion-panel-header
        ref="header"
        class="panel-header"
        @keyup.space.prevent
    >
      <v-row
          align="center"
          no-gutters
      >
        <v-avatar
            color="partition darken-3"
            size="28"
            class="mr-1"
        >
          <span> {{ partition.seq_number }}</span>
        </v-avatar>
        Partition:
        <v-col cols="8">
          <v-text-field
              v-model="partition.title"
              outlined
              dense
              hide-details
              placeholder="Titel eingeben.."
              class="ml-2"
              background-color="partition lighten-1"
              @click.stop
              @input="saveProjectAfterTimeout()"
          />
        </v-col>
      </v-row>
      <v-row
          justify="end"
          class="mr-4"
      >
        <slot
            name="header-options"
            :partition="partition"
        />
      </v-row>
    </v-expansion-panel-header>

    <v-expansion-panel-content color="partition darken-3">
      <!-- ================================     CHUNKS    ================================ -->
      <v-card
          outlined
          style="border: 2px solid white;"
          class="mt-2"
      >
        <v-card
            width="100%"
            class="pa-1 pl-2"
            color="chunk darken-1"
        >
          Chunks
        </v-card>
        <v-virtual-scroll
            ref="virtual-scroll"
            :items="chunksSorted"
            :item-height="225"
            :height="getVHeight"
            bench="4"
            style="overflow-x: hidden;"
        >
          <template v-slot="{ item }">
            <chunk-card
                :key="item.id"
                :ref="item.id"
                :chunk="item"
                :project-data="projectData"
                @create-new-chunk="onCreateNewChunk($event)"
                @save-project="saveProjectAfterTimeout"
                @silent-save="silentSave"
                @reload="reload"
            />
          </template>
        </v-virtual-scroll>
      </v-card>
      <!-- ================================================================================== -->

      <v-row
          v-if="!isLast"
          class="pt-4"
      >
        <v-toolbar
            color="partition"
            height="35"
        >
          <span> Zusammenfassung der Partition </span>
          <span
              v-if="partition.title"
              class="ml-1"
          >
                        "{{ partition.title }}"
                    </span>
        </v-toolbar>
        <v-tabs
            background-color="partition darken-1"
            color="white"
            height="30"
        >
          <v-tab
              v-for="partitionProfile in partition.partitionProfiles"
              :key="`${partitionProfile.partition_id} ${partitionProfile.profile_id}`"
              class="profile-tab-label"
          >
            {{ getProfileTitleById(partitionProfile.profile_id) }}
          </v-tab>
          <v-tab-item
              v-for="partitionProfile in partition.partitionProfiles"
              :key="`${partitionProfile.partition_id} ${partitionProfile.profile_id}`"
          >
            <v-card
                class="pa-1"
                color="partition darken-4"
            >
              <quill-editor
                  v-model="partitionProfile.content"
                  :height="100"
                  @input="saveProjectAfterTimeout()"
              />
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-expansion-panel-content>


  </v-expansion-panel>
</template>

<script>
import ChunkCard from '@/components/ContentManager/ChunkCard'
import {mapState, mapActions} from 'vuex'

export default {
  name: 'PartitionPanel',

  components: {
    ChunkCard,
  },

  props: {
    partition: {
      type: Object,
      required: true
    },
    itemheight: {
      type: Number,
      default: 1
    },
    projectData: {
      type: Object,
      default: Object
    },
    isLast: Boolean
  },

  data() {
    return {
      headerColor: 'partition'
    }
  },


  computed: {
    getVHeight() {
      return this.chunksSorted.length > 1 ? 230 * this.itemheight + (230 / 2) : 230
    },

    chunkEditorWidth() {
      return `${Math.max(90 / this.projectData.profiles.length, 22.5)}%`
    },

    chunksSorted() {
      const chunksToSort = this.partition.chunks
      return chunksToSort.sort((chunk1, chunk2) => chunk1.seq_number - chunk2.seq_number)
    },

    chunkMediaDialogImageUrl: {
      get() {
        return null
      },
      set(value) {
      }
    }
  },

  methods: {
    async silentSave() {
      try {
        await this.$emit('silent-save')
      } catch (e) {
        Promise.reject(e)
      }
    },
    saveProjectAfterTimeout() {
      this.$emit('save-project')
    },

    getProfileTitleById(profileId) {
      if (this.projectData) {
        const profile = this.projectData.profiles.find(profile => profile.id === profileId)
        return profile ? profile.title : null
      }
      return null
    },

    onCreateNewChunk(newChunk) {
      // DOES NOT WORK BECAUSE OF v-virtual-scroll
      /*
      setTimeout(() => {
          this.$refs[newChunk.id][0].animateBackground()
      }, 600)
      */
    },

    async animateHeaderColor() {
      await this.$vuetify.goTo(this.$refs.header)
      this.$refs.header.$el.classList.toggle('panel-header-animated')
      setTimeout(() => {
        this.$refs.header.$el.classList.toggle('panel-header-animated')
      }, 1200)
    },
    reload() {
      this.$emit('reload')
    }
  }
}
</script>

<style scoped>
.panel-header {
  background: #148D98;
  height: 30px;
}

.panel-header-animated {
  animation: blink 0.4s infinite;
}

@keyframes blink {
  0% {
    background: #148D98;
  }
  50% {
    background: green;
  }

  100% {
    background: #148D98;
  }
}

.profile-tab-label {
  text-transform: none !important;
}
</style>
