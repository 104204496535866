import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../components/Security/Login';

import store from '../store/index.js';
import _ from 'lodash';
import ContentManagerView from '../views/ContentManagerView';


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'ProjectManager',
        component: () => import('@/views/ProjectManager'),
        meta: {
            roles: ['USER', 'MANAGER'],
            requireLogin: true,
        },
    },
    {
        path: '/performance/:id',
        name: 'PerformanceManager',
        component: () => import('@/views/PerformanceManager'),
        meta: {
            roles: ['USER', 'MANAGER'],
            requireLogin: true,
        },
    },
    {
        path: '/hosts',
        name: 'host',
        component: () => import('@/views/HostManagement'),
        meta: {
            roles: ['SUPERUSER'],
            requireLogin: true,
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/AdminDashboard'),
        meta: {
            roles: ['MANAGER'],
            requireLogin: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            roles: [],
            requireLogin: false,
        },
    },
    {
        path: '/content-manager/:id',
        name: 'ContentManager',
        component: () => import('@/views/ContentManagerView'),
        meta: {
            roles: ['USER', 'MANAGER'],
            requireLogin: true,
        },
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    const requireLogin = to.meta.requireLogin;
    const requireRole = to.meta.roles;
    const userroles = store.getters['authentication/getUserroles'];

    if (requireRole?.length === 0) {
        next();
    } else if (_.intersection(_.map(userroles, function (o) { return o.name }), requireRole).length === 0) {
        console.log('Seite nicht gefunden - 404')
        console.log('Seite nicht gefunden - 404')
    } else {
        next();
    }
});

export default router;
