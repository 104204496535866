<template>
  <v-dialog
    :value="true"
    persistent
    :width="width"
    :height="height"
  >
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col
            v-if="iconName"
            cols="2"
            class="d-flex align-center"
          >
            <v-icon
              size="45"
              :color="type"
            >
              {{ iconName }}
            </v-icon>
          </v-col>
          <v-col
            :cols="iconName ? 10 : 12"
            class="d-flex align-center"
          >
            <span v-html="text" />
          </v-col>
        </v-row>
        <v-row
          class="pt-2 px-2"
        >
          <v-btn
            v-if="isDisplayingCountdown"
            @click="instantDelete"
          >
            Sofort
          </v-btn>

          <v-spacer />
          <v-btn
            v-if="!noCancelButton && !isDisplayingCountdown"
            class="mr-2"
            text
            @click="reply(false)"
          >
            {{ cancelText }}
          </v-btn>

          <v-btn
            v-if="isDisplayingCountdown"
            @click.stop="stopTimer"
          >

            <v-progress-circular
              :rotate="360"
              :size="30"
              :width="2"
              :value="getCountdownPercent"
              class="mr-2"
            >
              {{ countdown }}
            </v-progress-circular>
            cancel
          </v-btn>


          <v-btn
            v-else
            :color="type === 'error' ? null : type"
            @click="reply(true)"
          >
            {{ okText }}
          </v-btn>
        </v-row>
      </v-container>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: 'VConfirmDialog',
  props: {
    width: {
      type: String,
      default: '500px'
    },
    height: {
      type: String,
      default: '300px'
    },
    title: {
      type: String,
      default: 'Attention'
    },
    text: {
      type: String,
      default: 'Are you sure you want to perform this action?'
    },
    type: {
      type: String,
      default: null,
      validator: type => [null, undefined, 'info', 'success', 'warning', 'error', 'delete'].includes(type)
    },
    okText: {
      type: String,
      default: 'Ok'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    noCancelButton: {
      type: Boolean,
      default: false
    },
    showCountdown: {
      type: Boolean,
      default: false
    },
    countdowntimer: {
      type: Number,
      default: 5
    }
  },

  data() {
    return {
      response: true,
      countdown: 5,
      isDisplayingCountdown: false,
      intervalTimer: null,
    }
  },

  computed: {
    iconName() {
      switch (this.type) {
        case 'info':
          return 'mdi-information'
        case 'success':
          return 'mdi-check'
        case 'warning':
          return 'mdi-alert'
        case 'error':
          return 'mdi-alert-circle'
        case 'delete':
          return 'mdi-delete'
        default:
          return null
      }
    },
    getCountdownPercent() {
      return (this.countdowntimer / (this.countdowntimer - this.countdown)) * 100
    }
  },

  methods: {
    async reply(response) {
      if (!response) {
        this.response = response
        this.$destroy()
      }
      if (this.showCountdown) {
        this.isDisplayingCountdown = true
        this.countdown = this.countdowntimer || 5

        this.intervalTimer = window.setInterval(() => {
          if (this.countdown === 0) {
            this.isDisplayingCountdown = false
            window.clearInterval(this.intervalTimer)
            this.response = response
            this.$destroy()
          }
          this.countdown -= 1
        }, 1000)
      } else {
        this.response = response
        this.$destroy()
      }
    },
    stopTimer() {
      window.clearInterval(this.intervalTimer)
      this.isDisplayingCountdown = false
    },
    instantDelete() {
      this.response = true
      this.$destroy()
    }
  }
}
</script>
