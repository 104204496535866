<template>
  <v-app-bar
    color="primary"
    app
    dark
  >
    <v-row align="center">
      <v-col
        cols="4"
        class="d-flex align-center"
      >
        <v-img
          src="@/assets/operaguru.png"
          max-height="30px"
          max-width="30px"
          class="mr-2"
        />
        opera.guru
        <v-icon> mdi-chevron-right</v-icon>
        <span> {{ $route.name }} </span>
      </v-col>
      <v-col
        cols="8"
        class="d-flex justify-end align-center"
      >
        <v-btn
          text
          small
          depressed
          to="/"
        >
          Project Manager
        </v-btn>
        <v-btn
          v-if="hasRoles(['MANAGER', 'SUPERUSER'])"
          text
          small
          depressed
          to="/dashboard"
        >
          Dashboard
        </v-btn>
        <v-btn
          v-if="hasRole('SUPERUSER')"
          text
          small
          depressed
          to="/hosts"
        >
          OG Hosts
        </v-btn>
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn
              class="ma-2"
              style="min-width: 130px"
              text
              small
              depressed
              v-on="on"
            >
              <v-icon class="pr-2">
                mdi-account
              </v-icon>
              {{ user.username  || 'Benutzer' }}
              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="pwc">
              <v-list-item-title>Password change</v-list-item-title>
            </v-list-item>
            <v-list-item @click="doLogout()">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
            <v-divider class="mt-3" />
            <v-row
              no-gutters
              align="center"
              class="pa-1"
            >
              <v-col cols="3">
                <v-img
                  src="@/assets/operaguru.png"
                  max-height="20px"
                  max-width="20px"
                  class="ml-1 mr-1"
                />
              </v-col>
              <v-col
                cols="9"
                style="font-size: 0.6em;"
                class="d-flex flex-column"
              >
                <a
                  href="http://opera.guru"
                  target="blank"
                > &copy; opera.guru </a>
                <span> Management v{{ version() }} </span>
              </v-col>
            </v-row>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <Passwordchange
      :visible="dialog_pwc"
      @close="dialog_pwc = false"
    />
  </v-app-bar>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import AuthenticationService from '@/services/Authentication.service';
import Passwordchange from '@/components/Security/Passwordchange'

export default {
  name: 'Navigation',
  components: {Passwordchange},
  data: () => ({
    dialog_pwc: false
  }),
  computed: {
    ...mapState('authentication', ['status', 'user']),
  },
  methods: {
    ...mapMutations('authentication', ['logout', 'setDialogsPWC']),

    doLogout() {
      AuthenticationService.logout();
    },

    hasRole(role) {
      return (this.user?.userRoles?.filter(e => e.name === role).length > 0)
    },
    hasRoles(roles) {
      let check = false
      let i = 0
      while (i < roles.length && !check) {
        check = this.hasRole(roles[i])
        i++
      }
      return check
    },

    version() {
      return process.env.VUE_APP_VERSION
    },
    pwc() {
      this.dialog_pwc = true
    }

  }
}
</script>

<style scoped>

</style>
