import axios from 'axios';
import Vue from 'vue';

export const instantmessage = {
    namespaced: true,
    state: {
        host_id: 1,
        project_id: 1,
        instantmessagesHost: [],
        instantmessagesProject: [],
        instantmessageTranslations: [],
        instantmessage: {},
        instantmessageTranslation: {},

        loaders: {
            instantmessages: false,
            instantmessage: false,
            createInstant: false,
            fromHost: false,
        },
        dialogs: {
            detail: false,
            translation: false,
            fromHost: false,
        },

    },
    actions: {
        async loadInstantmessagesHost({commit, state}) {
            try {
                commit('setLoadersInstantmessages', true);
                const {data} = await axios.get('/api/instantmessage/' + state.host_id);
                commit('setInstantmessagesHost', data)
            } catch (err) {
                console.log(err);
            } finally {
                commit('setLoadersInstantmessages', false)
            }
        },

        async loadInstantmessage({commit, state}) {
            try {
                const {data} = await axios.get('/api/instantmessage/' + state.host_id + '/message/' + state.instantmessage.id);
                commit('setInstantmessage', data)
            } catch (err) {
                console.log(err);
            }
        },

        async loadInstantmessagesProject({commit, state, rootState}) {
            try {
                commit('setLoadersInstantmessages', true);
                const {data} = await axios.get('/api/instantmessage/' + state.host_id + '/project/' + rootState.performance.project_id);
                commit('setInstantmessagesProject', data)
            } catch (err) {
                console.log(err);
            } finally {
                commit('setLoadersInstantmessages', false)
            }
        },

        async addTranslation({commit, dispatch, state, rootState}, payload) {
            try {
                commit('setLoadersCreateInstant', true);
                const {data} = await axios.post('api/instantmessage/' + state.host_id +
                    '/message/' + payload.instantmessage_id +
                    '/addTranslation', payload);

                dispatch('loadInstantmessagesHost', data.projectId);
                dispatch('loadInstantmessage', data);
                commit('setDialogsTranslation', false);
                Vue.prototype.$toast('translation created', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.log(err);
            } finally {
                commit('setLoadersCreateInstant', false)
            }
        },

        async updateTranslation({commit, dispatch, state, rootState}, payload) {
            try {
                commit('setLoadersCreateInstant', true);
                const {data} = await axios.post('api/instantmessage/' + state.instantmessage.hostId +
                    '/message/' + payload.id +
                    '/updateTranslation', payload);

                dispatch('loadInstantmessages', data.projectId);
                dispatch('loadInstantmessage', data);
                commit('setDialogsTranslation', false);
                Vue.prototype.$toast('translation updated', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.log(err);
            } finally {
                commit('setLoadersCreateInstant', false)
            }
        },

        async addInstantmessageFromHostToProject({commit, dispatch, state, rootState}, payload) {
            try {
                commit('setLoadersFromHost', true);
                await axios.post('/api/instantmessage/' + rootState.authentication.user.host_id +
                    '/message/' + payload + '/addFromHost', {id: rootState.performance.project_id});

                dispatch('loadInstantmessagesProject');
                commit('setDialogsAddFromHost', false)
            } catch (err) {
                console.log(err);
            } finally {
                commit('setLoadersFromHost', false)
            }
        },


        async createInstantmessage({commit, dispatch, state, rootState}, payload) {
            try {
                commit('setLoadersCreateInstant', true);
                const {data} = await axios.post('/api/instantmessage/' + rootState.authentication.user.host_id +
                    '/message/create', payload);

                dispatch('loadInstantmessagesHost');
                dispatch('loadInstantmessagesProject');
                commit('setInstantmessage', data);
                dispatch('loadInstantmessage');
                Vue.prototype.$toast('instantmessage created', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.log(err);
            } finally {
                commit('setLoadersCreateInstant', false)
            }
        },

        async createInstantmessage_V1({commit, dispatch, state, rootState}, payload) {
            try {
                const {data} = await axios.post('/api/instantmessage/' + rootState.authentication.user.host_id +
                    '/message/createV1', payload);

                dispatch('loadInstantmessagesHost');
                dispatch('loadInstantmessagesProject');
                commit('setInstantmessage', data);
                dispatch('loadInstantmessage');
                Vue.prototype.$toast('instantmessage created', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.log(err);
            }
        },

        async updateInstantmessageV1({commit, dispatch, state, rootState}, payload) {
            try {
                const {data} = await axios.post('/api/instantmessage/' + rootState.authentication.user.host_id +
                    '/message/updateV1', payload);

                dispatch('loadInstantmessagesHost');
                dispatch('loadInstantmessagesProject');
                commit('setInstantmessage', data);
                dispatch('loadInstantmessage');
                Vue.prototype.$toast('instantmessage updated', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.log(err);
            }
        },

        async deleteInstantmessageV1({commit, dispatch, state, rootState}, payload) {
            try {
               await axios.post('/api/instantmessage/' + rootState.authentication.user.host_id +
                    '/message/deleteV1', payload);

                dispatch('loadInstantmessagesHost');
                dispatch('loadInstantmessagesProject');
                commit('setDialogsDetail', false);
                Vue.prototype.$toast('instantmessage deleted', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.log(err);
            }
        },

        async publishInstantmessage({commit, dispatch, state, rootState}, {project_id, instant_id}) {
            try {
                await axios.post('api/instantmessage/' + state.host_id + '/message/' + instant_id + '/publish');

                dispatch('loadInstantmessagesProject');
                Vue.prototype.$toast('instantmessage published', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.log(err);
            }
        },

        async unpublishInstantmessage({commit, dispatch, state, rootState}, {project_id, instant_id}) {
            try {
                await axios.post('api/instantmessage/' + state.host_id + '/message/' + instant_id + '/unpublish');

                dispatch('loadInstantmessagesProject', project_id);
                Vue.prototype.$toast('instantmessage unpublished', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.log(err);
            }
        },
    },
    mutations: {
        //Setters
        setInstantmessage(state, payload) {
            state.instantmessage = payload;
        },
        setInstantmessagesProject(state, payload) {
            state.instantmessagesProject = payload;
        },
        setInstantmessagesHost(state, payload) {
            state.instantmessagesHost = payload;
        },
        setInstantmessageTranslation(state, payload) {
            state.instantmessageTranslation = payload;
        },


        //Dialogs
        setDialogsDetail(state, visible) {
            state.dialogs.detail = visible;
        },
        setDialogsTranslation(state, visible) {
            state.dialogs.translation = visible;
        },
        setDialogsAddFromHost(state, visible) {
            state.dialogs.fromHost = visible;
        },


        //Loaders
        setLoadersInstantmessages(state, visible) {
            state.loaders.instantmessages = visible;
        },
        setLoadersCreateInstant(state, visible) {
            state.loaders.createInstant = visible
        },
        setLoadersFromHost(state, visible) {
            state.loaders.fromHost = visible
        },


    },
    getters: {
        getInstantmessagesProject(state, rootState) {
            return state.instantmessages.filter(e => e.projectId === rootState.performance.project_id)
        },

        getInstantmessagesHost(state) {
            return state.instantmessages.filter(e => e.projectId === null)
        },

    }
};
