const ValidationRulesMixin = {
  data: () => ({
    rules: {
      required: (name) => {
        return (value) => {
          return !!value || (name ? name + ' is required' : 'field is required')
        }
      },
      maxLength: (length) => {
        return (value) => {
          return ((value?.length || 0) <= length) || `max ${length} characters.`
        }
      },
      minLength: (length) => {
        return (value) => {
          return ((value?.length || 0) >= length) || `min ${length} characters.`
        }
      },
      gt: (val) => {
        return (value) => {
          return ((value > val) || `Betrag muss größer als ${val} sein`)
        }
      },
      gte: (val) => {
        return (value) => {
          return ((value >= val) || `Betrag muss größer gleich ${val} sein`)
        }
      },
      lt: (val) => {
        return (value) => {
          return ((value < val) || `Betrag muss kleiner als ${val} sein`)
        }
      },
      lte: (val) => {
        return (value) => {
          return ((value <= val) || `Betrag muss kleiner gleich ${val} sein`)
        }
      },
      password: (value) => {
        return /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*§?&])[A-Za-z\d@$!%*?§&]{8,})$/.test(value) || 'min 8 Characters, 1x uppercase, 1x digit, 1x special'
      },
      email: (v) => {
        return !v || /.+@.+\..+/.test(v) || 'E-mail must be valid'
      },

      date: (v) => {
        return !v || /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/.test(v) || 'Not a valid Date'
      },
    },
  })
}
export default ValidationRulesMixin
