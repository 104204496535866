<template>
  <v-app class="background">
    <Navigation v-if="status.loggedIn"/>
    <v-main color="primary">
      <Login :is-not-logged-in="!status.loggedIn"/>
      <v-container v-if="status.loggedIn" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import Navigation from '@/components/Navigation';
import {mapActions, mapState} from 'vuex';
import Login from './components/Security/Login';
import Passwordchange from './components/Security/Passwordchange';

export default {
  name: 'App',
  components: {
    Login,
    Navigation,
  },
  computed: {
    ...mapState('authentication', ['status', 'dialogs'])
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
.background {
  background-image: url('./assets/background.jpg') !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}
</style>

<style>
.v-card__title {
  font-family: Jura, sans-serif;
  font-size: 24px;
  line-height: 24px;
}
</style>
