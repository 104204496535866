import Vue from 'vue';
import Vuex from 'vuex';
import { project } from './project';
import { replaceStateReactiveCheckPlugin } from '@/store/reactiveCheck';
import {performance} from '@/store/performance';
import {hosts} from '@/store/hosts';
import {admin} from './admin';
import {globals} from './globals';
import {instantmessage} from './instantmessage';
import {VuexPersistence} from 'vuex-persist';
import authentication from '@/store/authentication.store';


Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        project,
        performance,
        hosts,
        admin,
        globals,
        instantmessage,
        authentication
    },
    plugins: [
        (new VuexPersistence({
            // modules: []
            modules: ['authentication', 'admin']
        })).plugin
    ],
    actions: {
        handleError(store, {text}) {
            const genericErrorText = 'Überprüfen Sie Ihre Verbindung oder versuchen Sie es später erneut.'
            const toastText = `${text} <br> ${genericErrorText}`
            Vue.prototype.$toast(toastText, {
                type: 'error',
                top: true,
                right: true,
            })
        }
    }
});

export default store;
