import {getField, updateField} from 'vuex-map-fields'
import moment from 'moment'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const jwtDecode = require('jwt-decode')

const state = {
  user: {},
  status: {
    loggedIn: false,
    logginIn: false,
  },
  login_error: null,
  expireTokenTime: null,
  dialog: {
    pwc: false
  }
}

const actions = {

}


const mutations = {
  SET_LOGIN_TRY(state) {
    state.status.logginIn = true
    state.login_error = null
  },
  SET_LOGIN_SUCCESS(state, payload) {
    state.status.logginIn = false
    state.status.loggedIn = true
    state.login_error = null
    state.user = payload
  },
  SET_LOGIN_ERROR(state, error) {
    state.status.logginIn = false
    state.status.loggedIn = false
    state.login_error = 'invalid credentials'
  },
  SET_LOGOUT(state) {
    state.status.logginIn = false
    state.status.loggedIn = false
    state.login_error = null
    state.user = {}
  },
  UPDATE_ACCESS_TOKEN(state, token) {
    state.user.access_token = token.access_token
    const decodedToken = jwtDecode(state.user.access_token)
    state.expireTokenTime = decodedToken.exp
  },
  SET_USER(state, user) {
    state.user = user.principal
  },

  updateField,
}

const getters = {
  getUserroles: (state) => {
    if (state.user) {
      return state.user.userRoles
    } else {
      return []
    }
  },
  getHostId: (state) => {
    return state.user.host_id
  },

  getField
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}


