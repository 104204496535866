import axios from 'axios';
import _ from 'lodash';
import Vue from 'vue';


export const performance = {
    namespaced: true,
    state: {
        chunks: {
            active: [],
            inactive: []
        },
        instantmessages: [],
        loaders: {
            instantmessages: false,
            instantmessage: false,
            createInstant: false,
        },
        dialogs: {
            detail: false,
            translation: false
        },
        instantmessage: {},
        instantmessageTranslation: {},
        project_id: null,
        profiles: [],
    },
    actions: {
        async loadChunks({commit}, payload) {
            console.log('asdasd2');
            try {
                const {data} = await axios.get('/api/performance/' + payload);
              console.log(data)
                commit('setChunks', data)
            } catch (err) {
                console.error(err);
            }
        },

        async loadInstantmessages({state, commit}) {
            try {
                commit('setLoadersInstantmessages', true);
                const {data} = await axios.get('/api/performance/projects/' + state.project_id + '/instantmessage');
                commit('setInstantmessages', data)
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersInstantmessages', false)
            }
        },

        async loadInstantmessage({commit, state}, payload) {
            try {
                const instant_id = payload.id || state.instantmessage.id;
                const project_id = state.projectId || state.instantmessage.projectId;
                commit('setLoadersInstantmessage', true);
                const {data} = await axios.get('api/performance/projects/' + project_id +
                    '/instantmessage/' + instant_id);

                commit('setInstantmessage', data)
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersInstantmessage', false)
            }
        },


        async next({commit, dispatch}, payload) {
            try {
                await axios.post('api/performance/projects/' + payload + '/forward');
                dispatch('loadChunks', payload)
            } catch (err) {
                console.error(err);
            }
        },

        async prev({commit, dispatch}, payload) {
            try {
                await axios.post('api/performance/projects/' + payload + '/backward');
                dispatch('loadChunks', payload)
            } catch (err) {
                console.error(err);
            }
        },

        async addTranslation({state, commit, dispatch}, payload) {
            try {
                commit('setLoadersCreateInstant', true);
                const {data} = await axios.post('api/performance/projects/' + state.instantmessage.projectId +
                    '/instantmessage/' + state.instantmessage.id +
                    '/addTranslation', payload);
                dispatch('loadInstantmessages', data.projectId);
                dispatch('loadInstantmessage', data);
                commit('setDialogsTranslation', false);
                Vue.prototype.$toast('translation created', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.error(err)
            } finally {
                commit('setLoadersCreateInstant', false);
            }
        },


        async removeTranslation({state, commit, dispatch}, payload) {
            try {
                commit('setLoadersCreateInstant', true);
                await axios.post('api/performance/projects/' + state.instantmessage.projectId +
                    '/instantmessage/' + state.instantmessage.id +
                    '/removeTranslation', payload);

                dispatch('loadInstantmessages', state.instantmessage.projectId);
                dispatch('loadInstantmessage', state.instantmessage.id);
                commit('setDialogsTranslation', false);
                Vue.prototype.$toast('translation deleted', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.error(err)
            } finally {
                commit('setLoadersCreateInstant', false);
            }
        },

        async deleteInstantmessage({state, commit, dispatch}, payload) {
            try {
                await axios.post('api/performance/projects/' + state.instantmessage.projectId +
                    '/instantmessage/' + state.instantmessage.id +
                    '/delete', payload);

                dispatch('loadInstantmessages', state.instantmessage.projectId);
                commit('setDialogsDetail', false);
                Vue.prototype.$toast('instantmessage deleted', {
                    type: 'success',
                    top: true,
                    right: true,
                    timeout: 3000,
                });
            } catch (err) {
                console.error(err)
            }
        },

        async getProfiles({state, commit, dispatch}, payload) {
            try {
                commit('setLoadersProfiles', true);
                const {data} = await axios.get('api/project/' + payload + '/profiles');
                commit('setProfiles', data)
            } catch (err) {
                console.error(err)
            } finally {
                commit('setLoadersProfiles', true)
            }
        }
    },
    mutations: {
        //Setters
        setChunks(state, payload) {
            state.chunks.active = _.orderBy(payload.active, ['activetimestamp'], ['desc']);
            state.chunks.inactive = _.orderBy(payload.inactive, ['partitionseq', 'chunkseq'], ['asc', 'asc']);
        },
        setInstantmessageTranslation(state, payload) {
            state.instantmessageTranslation = payload;
        },


        //Dialogs
        setDialogsTranslation(state, visible) {
            state.dialogs.translation = visible;
        },
        setProjectId(state, project_id) {
            state.project_id = project_id;
        },

        setProfiles(state, payload) {
            state.profiles = payload;
        },


        //Loaders
        setLoadersInstantmessages(state, visible) {
            state.loaders.instantmessages = visible;
        },
        setLoadersInstantmessage(state, visible) {
            state.loaders.instantmessage = visible;
        },
        setLoadersCreateInstant(state, visible) {
            state.loaders.createInstant = visible
        },
        setLoadersProfiles(state, visible) {
            state.loaders.profiles = visible
        },
    },
    getters: {
        projectInstantmessages: (state) => {
            return state.instantmessageTranslation.filter(i => i.projectId === 1)
        },
        getProjectLanguages: (state) => {
            return _.uniqBy(state.profiles.map(e => e.language), 'id')
        }

    },
};
