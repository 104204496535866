<template>
    <transition name="fade-transition">
        <div class="loading-screen">
            <v-progress-circular
                :size="80"
                :width="15"
                color="primary"
                indeterminate
            />
            <span class="pa-2"> {{ label }} </span>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: null
        }
    }
}
</script>

<style scoped>
    .loading-screen {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,0.8);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

</style>

