import Vue from 'vue'
import App from '@/App.vue'
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios'

import CKEditor from '@ckeditor/ckeditor5-vue';
import moment from 'moment';
import {authentication} from './store/authentication';
import VToast from './components/_Helper/VToast';
import VConfirmDialog from './components/_Helper/VConfirmDialog';
import ActionButton from './components/ActionButton';

import VLoadingScreen from './components/VLoadingScreen';
import QuillEditor from './components/_Helper/QuillEditor'
import 'quill/dist/quill.bubble.css'

import vueShortkey from 'vue-shortkey'
Vue.component('quill-editor', QuillEditor);
Vue.component('action-button', ActionButton)

Vue.component('v-loading-screen', VLoadingScreen)

Vue.use(CKEditor);
Vue.use(vueShortkey)
Vue.use(vueShortkey, { prevent: ['input', '.textarea'] })
Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(Vuex);

// register the ActionButton component globally:
Vue.component('action-button', ActionButton)


Vue.prototype.$dt = function (datum) {
    const d = moment(datum);
    return d.isValid() ? d.format('DD.MM.YYYY - HH:mm') : '-'
};
Vue.prototype.$d = function (datum) {
    const d = moment(datum);
    return d.isValid() ? d.format('DD.MM.YYYY') : '-'
};

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL || '';
axios.interceptors.response.use((response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            console.log('not logged in');
            store.commit('authentication/SET_LOGOUT', null, {root: true});
        }
        return Promise.reject(error.response);
    });

axios.interceptors.request.use(
    config => {
        config.headers.common['Access-Control-Allow-Origin'] = '*';
        config.withCredentials = true;
        return config;
    },
    error => Promise.reject(error)
);

/* -------------------- CONFIRM DIALOG ($confirm) -------------------- */
function renderConfirmDialog(text, props) {
    return new Promise(resolve => {
        Vue.use(vuetify)
        const VueConfirmDialog = Vue.extend(Object.assign({ vuetify }, VConfirmDialog))
        const confirmDialog = new VueConfirmDialog(Object.assign(
            {},
            {
                propsData: {text, ...props},
                destroyed: () => {
                    document.body.removeChild(confirmDialog.$el)
                    resolve(confirmDialog.response)
                }
            },
        ))
        document.body.appendChild(confirmDialog.$mount().$el)
    })
}
Vue.prototype.$confirm = renderConfirmDialog
/* -------------------------------------------------------------------- */

/* -------------------- TOAST DIALOG ($toast) -------------------- */
function renderToast(text, props) {
    Vue.use(vuetify)
    const VToastConstructor = Vue.extend(Object.assign({ vuetify }, VToast))
    const toastComponent = new VToastConstructor(Object.assign(
        {},
        {
            propsData: {text, ...props},
            destroyed: () => {
                document.body.removeChild(toastComponent.$el)
            }
        },
    ))
    const vApp = document.querySelector('.v-application')
    vApp.appendChild(toastComponent.$mount().$el)
}
Vue.prototype.$toast = renderToast
/* -------------------------------------------------------------------- */

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App),
}).$mount('#app');
