<template>
  <v-dialog
    :value="true"
    width="75vw"
    persistent
  >
    <v-toolbar dense>
      <h3>Profile bearbeiten</h3>
    </v-toolbar>
    <v-card
      width="100%"
      height="100%"
      class="pa-4"
    >
      <v-row>
        <!--===================================== -->
        <!--  ======== THE PROFILE CARDS ======== -->
        <!--===================================== -->
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-sheet
            max-width="100%"
            class="mx-auto"
            color="rgba(0, 0, 0, 0)"
          >
            <v-slide-group
              class="slide-group"
              show-arrows
            >
              <v-slide-item
                v-for="profile of profiles"
                :key="profile.id"
              >
                <v-menu
                  open-on-hover
                  top
                  nudge-top="60px"
                  transition="scroll-y-transition"
                >

                  <template #activator="{ on }">
                    <v-card
                      width="120px"
                      height="80px"
                      class="ma-1 pa-1"
                      color="primary darken-1"
                      v-on="on"
                    >
                      <div class="profile-title">
                        {{ profile.title }}
                      </div>
                    </v-card>
                  </template>
                  <!-- =================================== -->
                  <!-- OPTIONS (TO EDIT OR DELETE PROFILE) -->
                  <!-- =================================== -->
                  <v-card
                    height="45px"
                    color="primary darken-2"
                  >
                    <v-row
                      no-gutters
                      justify="center"
                      align="center"
                      style="height: 100%"
                    >
                      <action-button
                        color="primary"
                        tooltip-text="Profil umbenennen"
                        icon="mdi-account-edit"
                        @click="openProfileDialogEdit(profile)"
                      />
                      <action-button
                        color="delete"
                        tooltip-text="Profil löschen"
                        icon="mdi-delete"
                        @click="confirmDeleteProfile(profile)"
                      />
                    </v-row>
                  </v-card>
                </v-menu>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>

        <v-col
          cols="12"
          class="d-flex justify-end"
        >
          <v-btn
            small
            color="primary"
            class="mr-2"
            @click="openProfileDialogCreate()"
          >
            <v-icon left> mdi-account-plus</v-icon>
            Neues Profil
          </v-btn>
          <v-btn
            small
            color="success"
            @click="$emit('close')"
          >
            <v-icon left> mdi-check</v-icon>
            Fertig
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <profile-dialog
      v-if="showProfileDialog"
      :profile="profileToEdit"
      :mode="profileDialogMode"
      @close="showProfileDialog = false"
      @reload="reload"
    />
  </v-dialog>
</template>

<script>
import Profile from '@/types/Profile'
import {mapState, mapActions} from 'vuex'
import ProfileDialog from '@/components/ContentManager/ProfileDialog'
import contentmanagerService from '@/services/Contentmanager.service'

export default {
  name: 'ProjectProfilesDialog',

  components: {
    ProfileDialog
  },
  props: {
    projectId: {
      type: Number,
      required: true,
      default: Number
    },
    profiles: {
      type: Array,
      default: Array
    }
  },

  data() {
    return {
      showProfileDialog: false,
      profileToEdit: null,
      profileDialogMode: 'create',
      profileNameRules: [
        value => (value != null && value !== '') || 'Profilname muss angegeben werden'
      ]
    }
  },

  computed: {},

  methods: {
    openProfileDialogCreate() {
      this.profileToEdit = new Profile(this.projectId)
      this.profileDialogMode = 'create'
      this.showProfileDialog = true
    },

    openProfileDialogEdit(profile) {
      this.profileToEdit = Object.assign({}, profile)
      this.profileDialogMode = 'edit'
      this.showProfileDialog = true
    },

    async confirmDeleteProfile(profile) {
      if (this.profiles.length === 1) {
        this.$confirm(
          'Dieses Profil kann nicht gelöscht werden, da es das einzige Profil in diesem Projekt ist',
          {
            type: 'warning',
            noCancelButton: true
          }
        )
      } else {
        const answer = await this.$confirm(
          `Möchten Sie wirklich das Profil "${profile.title}" und alle dazugehörigen Chunk-Inhalte löschen?`,
          {
            type: 'delete',
            okText: 'Löschen',
            cancelText: 'Profil beibehalten',
          }
        )
        if (answer) {
          try {
            await contentmanagerService.deleteProfile(profile.id)
            this.$toast('profile created', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
          } catch (e) {
            this.$toast('create error', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        }
      }
    },

    reload() {
      this.$emit('reload')
    }
  }
}
</script>

<style scoped>
.profile-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}
</style>
