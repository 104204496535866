import store from '../store'
import axios from 'axios';


class AuthenticationServiceClass {

  async login(credentials) {
    try {
      console.log('login', credentials)
      store.commit('authentication/SET_LOGIN_TRY')
      const {data} = await axios.post('/login', credentials)
      console.log(data)
      store.commit('authentication/SET_LOGIN_SUCCESS', data)
      console.log(data)
      return data
    } catch (err) {
      store.commit('authentication/SET_LOGIN_ERROR', err)
      await Promise.reject(err)
    }
  }

  async getAuthData() {
    try {
      const {data} = await axios.get('/auth/user')
      console.log(data.principal)
      store.commit('admin/setHostId', data.principal.hostId, {root: true})
      store.commit('authentication/SET_USER', data)
    } catch (err) {
      await Promise.reject(err)
    }
  }

  async updatePassword(payload) {
    try {
      const {data} = await axios.post('/auth/passwordchange', payload)
      return data
    } catch (err) {
      await Promise.reject(err)
    }
  }

  async logout() {
    try {
      store.commit('authentication/SET_LOGOUT')
    } catch (err) {
      await Promise.reject(err)
    }
  }

}

const AuthenticationService = new AuthenticationServiceClass()
export default AuthenticationService
