<template>
  <v-card
    dark
    color="#333"
    :width="width"
    class="ma-1 chunk-editor-container"
    :style="editorContainerStyle"
  >
    <v-toolbar
      height="30px"
      color="teal darken-3"
    >
      <v-row align="center">
        <v-col
          cols="10"
          class="profile-chunk-title"
        >
          {{ profile.title }}
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-center align-center"
        >
          <action-button
            color="chunk darken-1"
            icon="mdi-image-edit"
            tooltip-text="Medieninhalt für dieses Profil bearbeiten"
            :height="22"
            :width="22"
            @click="$emit('click-edit-media')"
          />
        </v-col>
      </v-row>
    </v-toolbar>
    <div class="bg pa-1">
      <quill-editor
        ref="editor"
        v-model="profileChunk.content"
        :height="profileChunk.media_url ? 90 : 125"
        :bounds="`#chunk_${profileChunk.chunk_id} .v-slide-group__content`"
        @toolbar-show="isToolbarVisible = true"
        @toolbar-hide="isToolbarVisible = false"
        @input="saveProjectAfterTimeout('input')"
        @blur="saveProjectAfterTimeout('blur')"
        @paste="saveProjectAfterTimeout('paste')"
      />
    </div>
    <div
      v-if="profileChunk.media_url && profileChunk.media_url != 'NULL'"
      class="chunk-editor-image-toolbar"
    >
      <img
        :src="profileChunk.media_url"
        height="25"
        class="ml-1 mr-1"
        @click="$emit('click-edit-media')"
      />
    </div>
  </v-card>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'profileChunkEditor',

  props: {
    profileChunk: {
      type: Object,
      default: null,
    },
    width: {
      type: String,
      default: '100%'
    },
    projectData: {
      type: Object,
      default: Object
    }
  },

  data() {
    return {
      isToolbarVisible: false,
      zIndex: 0
    }
  },

  computed: {

    profile() {
      return this.projectData ? this.projectData.profiles.find(profile => profile.id === this.profileChunk.profile_id) : null
    },

    // the z-index of the container of the rich-text editor must be higher
    // than of the adjacent containers so that the toolbar bubble is "above" instead of "underneath" them
    editorContainerStyle() {
      return {
        zIndex: this.isToolbarVisible ? 2 : 1
      }
    }
  },

  methods: {
    saveProjectAfterTimeout() {
      this.$emit('save-project')
    },


    toggleImageToolbar() {
      this.showImageToolbar = !this.showImageToolbar
    }

  },
}
</script>

<style scoped>
.bg {
  /*background-color: white;*/
  color: lightgrey;
  font-size: 0.9em;
  height: 130px;
  overflow: visible;
  z-index: 2;
}

.chunk-editor-container {
  z-index: 2;
}

.chunk-editor-image-toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 5px;
}

.chunk-editor-image-toolbar >>> img:hover {
  cursor: pointer;
}

.profile-chunk-title {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
