<template>
  <v-dialog
    :value="visible"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        Password Change
      </v-card-title>
      <v-card-text>
        <v-form ref="pwc">
          <v-text-field
            v-model="form.oldPassword"
            label="old pfrassword"
            name="password"
            :rules="[rules.required()]"
            outlined
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="new"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            v-model="form.newPassword"
            label="new password"
            name="password"
            :rules="[rules.required()]"
            outlined
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="new"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            v-model="form.newPasswordConfirmed"
            label="confirm password"
            name="password"
            :rules="[rules.required()]"
            outlined
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="new"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          depressed
          small
          color="primary"
          :loading="loaders_pwc"
          @click="save"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          small
          color="primary"
          @click="close"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin';

export default {
  name: 'Passwordchange',
  mixins: [ValidationRulesMixin],
  props: {
    visible: Boolean
  },
  data() {
    return {
      loaders_pwc: false,
      form: {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmed: '',
      },
      showPassword: false,
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('authentication', ['updatePassword']),
    close() {
      this.$emit('close')
    },
    save() {
      if (!this.$refs.pwc.validate()) {
        return false;
      }

      if (this.form.newPasswordConfirmed !== this.form.newPassword) {
        this.$toast('new Password doesn\'t match', {
          type: 'error',
          top: true,
          right: true,
          timeout: 3000,
        })
        return false;
      }
      this.loaders_pwc = true
      this.updatePassword(this.form)
      .then(() => {
        this.loaders_pwc = false
      })

    }
  }
}
</script>

<style scoped>

</style>
