import axios from 'axios'
import moment from 'moment'

export const globals = {
  namespaced: true,
  state: {
    roles: [],
    languages: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
  },
  actions: {
    async loadRoles({commit, rootState}) {
      try {
        const {data} = await axios.get('/api/globals/roles')
        // const roles = rootState.authentication.user.userRoles
        // console.log(data, roles)

        commit('setRoles', data)
      } catch (err) {
        console.error(err)
      }
    },

    async loadLanguages({commit}) {
      try {
        const {data} = await axios.get('/api/language/')
        commit('setLanguages', data)
      } catch (err) {
        console.error(err)
      }
    },
  },
  mutations: {
    setRoles(state, roles) {
      state.roles = roles
    },
    setLanguages(state, payload) {
      state.languages = payload
    },

  },
  getters: {
    getTimeslots: () => {
      const timeStops = []
      const startTime = moment('00:00', 'HH:mm')
      const endTime = moment('23:45', 'HH:mm')

      // eslint-disable-next-line no-unmodified-loop-condition
      while (startTime <= endTime) {
        timeStops.push(moment(startTime).format('HH:mm'))
        startTime.add(15, 'minutes')
      }
      return timeStops
    },
  }
}
