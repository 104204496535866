export default class Project {
    id = null;
    name = null;
    imgurl = null;
    active = false;
    hostId= null;


    constructor(host_id) {
        this.hostId = host_id;
    }


    /*
    ALTER TABLE `projects`
        DROP FOREIGN KEY `FK_projects_hosts`;
    ALTER TABLE `projects`
        CHANGE COLUMN `hosts_id` `host_id` INT(20) UNSIGNED NOT NULL AFTER `lastupdated`,
        DROP INDEX `FK_projects_hosts`,
        ADD INDEX `FK_projects_hosts` (`host_id`) USING BTREE,
        ADD CONSTRAINT `FK_projects_hosts` FOREIGN KEY (`host_id`) REFERENCES `operaguru`.`hosts` (`id`) ON UPDATE RESTRICT ON DELETE RESTRICT;


     */
}

