<template>
  <v-dialog
    :value="true"
    width="50vw"
    persistent
  >
    <v-toolbar
      color="chunk"
      dense
    >
      Medieninhalt bearbeiten
    </v-toolbar>
    <v-card
      width="100%"
      class="pa-4"
    >
      <v-row
        justify="center"
        align="center"
        no-gutters
      >
        <v-col
          cols="6"
          class="pa-1"
        >
          <v-text-field
            :value="chunk.title != null && chunk.title != '' ? chunk.title : ' '"
            label="Chunk"
            dense
            readonly
            hide-details
            disabled
            style="opacity: 0.6;"
          >
            <template #prepend-inner>
              <v-avatar
                size="22"
                color="chunk"
              >
                            <span color="white">
                                {{ chunk.seq_number }}
                            </span>
              </v-avatar>
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pa-1"
        >
          <v-text-field
            v-model="profileTitle"
            label="Profil"
            dense
            readonly
            hide-details
            disabled
            style="opacity: 0.6;"
          />
        </v-col>
        <v-col
          cols="11"
          class="pa-1 mt-3"
        >
          <v-text-field
            v-model="imageURL"
            label="Link"
            placeholder="Link (URL) des Fotos hier eingeben"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-link-variant"
          />
        </v-col>
        <v-col
          cols="1"
          class="mt-3"
        >
          <action-button
            color="delete darken-1"
            icon="mdi-delete"
            tooltip-text="Medieninhalt löschen"
            :disabled="!imageURL"
            @click="imageURL = null"
          />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center ma-4"
        >
          <div id="image-container">
            <div id="image-container-bg">
              <v-icon
                v-if="!imageURL"
                size="120"
                color="grey darken-2"
              >
                mdi-file-image
              </v-icon>
            </div>
            <img
              v-if="imageURL"
              :src="imageURL"
            >
          </div>
        </v-col>
        <v-col
          v-if="hasChunkMultipleImageURLs && !profileChunk"
          cols="12"
          class="pa-2"
        >
          <v-alert
            type="warning"
            dense
            outlined
            class="text-caption"
          >
            <b> Achtung: </b>
            Mindestens ein Profil dieses Chunks hat einen eigenen Medieninhalt. Alle Änderungen hier werden diese
            Inhalte überschreiben.
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-end"
        >
          <v-btn
            small
            text
            class="mr-2"
            :loading="loaders.project"
            @click="onClickCancel()"
          >
            Abbrechen
          </v-btn>
          <v-btn
            small
            color="success"
            :loading="loaders.project"
            @click="onClickDone()"
          >
            <v-icon left> mdi-check</v-icon>
            Fertig
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ChunkMediaDialog',

  props: {
    chunk: {
      type: Object,
      required: true
    },
    profileChunk: { // if null, the media will be added to all profile chunks of the chunk
      type: Object,
      default: null
    },
    projectData: {
      type: Object,
      default: Object
    }
  },

  data() {
    return {
      initialImageURL: null,
      imageURL: null,
    }
  },

  computed: {
    ...mapState('project', [
      'loaders',
    ]),

    title() {
      return `
                Medieninhalt für Chunk 
                ${this.chunk.seq_number} ${this.chunk.title ? '"' + this.chunk.title + '"' : ''}
            `
    },

    profileTitle() {
      if (this.profileChunk) {
        const profile = this.projectData.profiles.find(profile => profile.id === this.profileChunk.profile_id)
        return profile ? profile.title : null
      } else {
        return 'Alle Profile'
      }
    },

    hasChunkMultipleImageURLs() {
      const prevImageURL = this.chunk.profileChunks[0].media_url
      for (const profileChunk of this.chunk.profileChunks) {
        if (profileChunk.media_url !== prevImageURL) {
          return true
        }
      }
      return false
    }
  },

  mounted() {
    if (this.profileChunk) {
      this.imageURL = this.profileChunk.media_url
    } else {
      if (!this.hasChunkMultipleImageURLs) {
        this.imageURL = this.chunk.profileChunks[0].media_url
      }
    }
    this.initialImageURL = this.imageURL
  },

  methods: {
    ...mapActions('project', ['updateProject']),

    onClickCancel() {
      if (this.profileChunk) {
        this.profileChunk.media_url = this.initialImageURL
      }
      this.$emit('close')
    },

    async onClickDone() {
      if (this.profileChunk) {
        this.profileChunk.media_url = this.imageURL
      } else {
        if (this.hasChunkMultipleImageURLs && !this.imageURL) {
          const answer = await this.$confirm(
            `
                        Sie haben keinen Link angegeben, was dazu führen wird, dass alle Medieninhalte 
                        in allen Profilen dieses Chunks gelöscht werden. <br>
                        Möchten Sie trotzdem fortfahren?`,
            {
              type: 'warning',
              okText: 'Fortfahren',
              cancelText: 'Abbrechen',
            }
          )
          if (!answer) {
            return
          }
        }
        for (const profileChunk of this.chunk.profileChunks) {
          profileChunk.media_url = this.imageURL
        }
      }

      //await this.updateProject(this.projectData)
      this.$emit('save-project')
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
#image-container {
  height: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#image-container-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-height: 200px;
  z-index: 2;
}
</style>

