export default class User {
    id = null;
    firstname = null;
    lastname = null;
    userRoles = null;
    username = null;
    active = false;
    host_id = null;
    password = null;
    password_confirmation = null;


    constructor(host_id) {
        this.host_id = host_id;
    }

    get name() {
        return this.firstname + ' ' + this.lastname
    }

    hasRole(role) {
        return this.userRoles.filter(e => e.name === role).length > 0;
    }
}
