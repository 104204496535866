<template>
  <div>
    <v-loading-screen
      v-if="loading_project"
      label="Projekt wird geladen..."
    />
    <v-toolbar
      style="position: sticky; top: 48px; left:0; z-index: 3;"
      dense
    >
      <v-toolbar-title>
        <v-icon left>
          mdi-subtitles-outline
        </v-icon>
        <span class="font-weight-light"> {{ projectData.name }}</span>
      </v-toolbar-title>
      <v-row
        justify="end"
        align="center"
      >
        <v-btn
          small
          color="partition"
          class="ml-1 mr-1"
          @click="addPartitionAfter(projectData.partitions.length)"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Neue Partition
        </v-btn>
        <v-btn
          small
          class="ml-1 mr-1"
          color="primary"
          @click="showProfileDialog = true"
        >
          <v-icon left>
            mdi-account-edit
          </v-icon>
          Profile bearbeiten
        </v-btn>
        <v-btn
          small
          color="success"
          class="ml-1 mr-1"
          @click="saveProjectAfterTimeout()"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Speichern
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-cog
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  Anzahl der Chunks / Partition
                </v-list-item-title>
                <v-list-item-subtitle>
                  Gilt nur bei mehreren Chunks / Partition
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-spacer></v-spacer>
              <v-btn-toggle
                v-model="itemheight"
                mandatory
              >
                <v-btn :value="1">
                  1
                </v-btn>
                <v-btn :value="2">
                  2
                </v-btn>
                <v-btn :value="3">
                  3
                </v-btn>
                <v-btn :value="4">
                  4
                </v-btn>
              </v-btn-toggle>
              <v-spacer></v-spacer>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </v-toolbar>

    <v-expansion-panels>
      <transition-group
        name="partition-transition"
        class="partition-transition-wrapper"
      >
        <partition-panel
          v-for="(partition, index) in partitionsSorted"
          :key="partition.id"
          :ref="partition.id"
          :itemheight="itemheight"
          :partition="partition"
          :project-data="projectData"
          :is-last="isLast(index)"
          @save-project="saveProjectAfterTimeout"
          @silent-save="silentSave"
          @reload="reload"
        >
          <template #header-options>
            <action-button
              color="partition darken-1"
              icon="mdi-table-row-plus-after"
              tooltip-text="Neue Partition nach dieser Partition einfügen"
              @click.stop="addPartitionAfter(partition.seq_number)"
            />
            <action-button
              color="delete"
              icon="mdi-delete"
              tooltip-text="Partition löschen"
              @click.stop="confirmDeletePartition(partition)"
            />
          </template>
        </partition-panel>
      </transition-group>
    </v-expansion-panels>

    <project-profiles-dialog
      v-if="showProfileDialog"
      :project-id="projectData.id || null"
      :profiles="projectData.profiles || []"
      @close="showProfileDialog = false"
      @reload="loadProject(projectData.id)"
    />
  </div>
</template>

<script>
import Partition from '@/types/Partition'
import PartitionPanel from '@/components/ContentManager/PartitionPanel'
import ProjectProfilesDialog from '@/components/ContentManager/ProjectProfilesDialog'
import {mapState, mapActions} from 'vuex'
import contentmanagerService from '@/services/Contentmanager.service'
import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import {mapFields} from 'vuex-map-fields'

export default {

  name: 'ContentManagerView',

  components: {
    PartitionPanel,
    ProjectProfilesDialog
  },

  data() {
    return {
      debounceSave: null,
      projectId: 1,
      projectData: {},
      profiles: [
        {
          title: 'Deutsch',
          sequenceNumber: 0
        },
        {
          title: 'English',
          sequenceNumber: 1
        },
        {
          title: 'Italiano',
          sequenceNumber: 2
        }
      ],
      showProfileDialog: false,
      loading_project: false,
    }
  },

  computed: {
    ...mapFields('admin', ['itemheight']),

    partitionsSorted() {
      let partitionsToSort = []
      if (this.projectData && this.projectData.partitions) {
        partitionsToSort = this.projectData.partitions
      }
      return partitionsToSort.sort((partition1, partition2) => partition1.seq_number - partition2.seq_number)
    }
  },

  mounted() {
    this.loadProject(this.$route.params.id)
  },

  methods: {

    async loadProject() {
      this.loading_project = true
      this.projectData = await contentmanagerService.loadProject(this.$route.params.id)
      this.loading_project = false
    },

    async silentSave() {
      console.log('silent Save')
      try {
        await contentmanagerService.saveProject(this.projectData)
      } catch (e) {
       Promise.reject(e)
      }
    },

    async saveProjectAfterTimeout() {
      console.log(this.projectData)
      if (!this.debounceSave) {
        this.debounceSave = _.debounce(async () => {
          Vue.prototype.$toast('Änderungen werden gespeichert!', {
            type: 'info',
            top: true,
            right: true,
            timeout: 2000,
          })
          await contentmanagerService.saveProject(this.projectData)
          Vue.prototype.$toast('Projekt gespeichert!', {
            type: 'success',
            top: true,
            right: true,
            timeout: 3000,
          })
          this.debounceSave = null
        }, 3000)
      }
      this.debounceSave()
    },


    async addPartitionAfter(position) {
      try {
        await this.silentSave()
        const partition = await contentmanagerService.createPartition(position, this.projectData.id)
        await this.loadProject(this.$route.params.id)
        setTimeout(() => {
          this.$refs[partition.id][0].animateHeaderColor()
        }, 600)
      } catch (e) {
        Vue.prototype.$toast('Partition create error', {
          type: 'error',
          top: true,
          right: true,
          timeout: 3000,
        })
      }
    },

    async confirmDeletePartition(partition) {
      if (this.projectData.partitions.length === 1) {
        this.$confirm(
          'Diese Partition kann nicht gelöscht werden, da es die einzige Partition in diesem Projekt ist',
          {
            type: 'warning',
            noCancelButton: true
          }
        )
        return
      }
      const answer = await this.$confirm(
        `Möchten Sie wirklich Partition ${partition.seq_number} ${partition.title || ''} löschen?`,
        {
          type: 'delete',
          okText: 'Löschen',
          cancelText: 'Partition beibehalten',
          showCountdown: true,
          countdowntimer: 5
        }
      )
      if (answer) {
        try {
          await contentmanagerService.deletePartition(partition.id)
          await this.loadProject(this.$route.params.id)
          Vue.prototype.$toast('Partition deleted', {
            type: 'success',
            top: true,
            right: true,
            timeout: 3000,
          })
        } catch (err) {
          Vue.prototype.$toast('Partition deletion error', {
            type: 'error',
            top: true,
            right: true,
            timeout: 3000,
          })
        }
      }
    },
    reload() {
      this.loadProject(this.$route.params.id)
    },
    isLast(index) {
      return index === this.partitionsSorted.length - 1
    }
  }
}
</script>

<style scoped>
.partition-transition-wrapper {
  width: 100%;
}

.partition-transition-enter-active, .partition-transition-leave-active {
  transition: all 0.5s;
}

.partition-transition-enter, .partition-transition-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
