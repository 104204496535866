<template>
    <div>
        <v-snackbar
            :value="show"
            :top="top"
            :bottom="bottom"
            :left="left"
            :right="right"
            :color="type"
            :timeout="timeout"
        >
            <v-row no-gutters align="center">
                <v-col cols="1">
                    <v-icon left color="white">
                        {{ iconName }}
                    </v-icon>
                </v-col>
                <v-col :cols="actionButton ? 6 : 11">
                    <span v-html="text"/>
                </v-col>
                <v-col 
                    v-if="actionButton"
                    cols="5"
                    class="d-flex justify-end align-center"
                >
                    <v-btn 
                        small
                        :color="`${type} darken-1`"
                        @click="onActionButtonClick()"
                    >
                        <v-icon 
                            v-if="actionButton.icon"
                            left
                        >
                            {{ actionButton.icon }}
                        </v-icon>
                        {{ actionButton.text }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-snackbar>
    </div>
</template>

<script>

export default {
    name: 'VToast',

    props: {
        text: {
            type: String,
            default: 'Information'
        },
        type: {
            type: String,
            default: 'info',
            validator: type => [null, undefined, 'info', 'success', 'warning', 'error', 'delete'].includes(type)
        },
        top: {
            type: Boolean,
            default: true
        },
        right: {
            type: Boolean,
            default: true
        },
        bottom: Boolean,
        left: Boolean,
        timeout: {
            type: Number,
            default: 6000
        },
        actionButton: {
            type: Object,
            default: null,
            validator: (val) => {
                return val.text != null && val.onClick != null
            }
        }
    },

    data() {
        return {
            show: false
        }
    },

    computed: {
        iconName() {
            switch (this.type) {
                case 'info':
                    return 'mdi-information'
                case 'success':
                    return 'mdi-check'
                case 'warning':
                    return 'mdi-alert'
                case 'error':
                    return 'mdi-alert-circle'
                case 'delete':
                    return 'mdi-delete'
                default:
                    return null
            }
        },
    },

    mounted() {
        this.show = true
    },

    methods: {
        onActionButtonClick() {
            this.actionButton.onClick()
            if (this.actionButton.closeOnClick) {
                this.show = false
            }
        }
    }
}
</script>
