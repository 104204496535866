<template>
  <v-dialog
    :value="isNotLoggedIn"
    max-width="500"
    persistent
    overlay-color="black"
  >
    <v-card elevation="10">
      <v-card-title>
                <span class="login-title">
                    Welcome to Opera.Guru
                </span>
        <v-spacer/>
        <v-img
          src="@/assets/operaguru.png"
          max-height="70px"
          width="70px"
        ></v-img>
      </v-card-title>
      <v-card-text>
        <v-form ref="Login" lazy-validation>
          <v-text-field
            v-model="form.username"
            label="Username"
            name="username"
            :rules="[rules.required, rules.maxLength(50)]"
            outlined
            prepend-icon="mdi-account"
            append-icon=""
            autocomplete="new"
          />
          <v-text-field
            v-model="form.password"
            label="Password"
            name="password"
            :rules="[rules.required]"
            outlined
            hint="forgot Password? click HERE"
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="new"
            @click:append="showPassword = !showPassword"
            @keydown.enter="tryLogin()"
          />

          <v-alert v-if="login_error" type="error">
            {{ login_error }}
          </v-alert>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          outlined
          color="success"
          :loading="status.loggingIn"
          @click="tryLogin()"
        >
          Login
          <v-icon right>
            mdi-login-variant
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import AuthenticationService from '@/services/Authentication.service';
import ValidationRulesMixin from '@/mixins/ValidationrulesMixin';

export default {
  name: 'Login',
  props: {
    isNotLoggedIn: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      rules: {
        required: (value) => {
          return !!value || 'required.';
        },
        maxLength: (length) => {
          return (value) => {
            return ((value?.length || 0) <= length) || `max ${length} characters.`;
          };
        },
      },
      form: {
        username: '',
        password: ''
      },
      showPassword: false
    }
  },
  computed: {
    ...mapState('authentication', ['status', 'login_error'])
  },
  methods: {
    ...mapActions('authentication', ['login']),

    async tryLogin() {
      if (!this.$refs.Login.validate()) {
        return
      }
      await AuthenticationService.login(this.form)
        .then(async () => {
          await AuthenticationService.getAuthData()
          this.$router.replace('/')
        })
        .then(() => {
          this.loadRoles()
          this.loadLanguages()
        })
        .catch(() => {
          console.log('error loggin in')
        })
    }
  }

}
</script>

<style scoped>
.login-title {
  font-family: jura, serif;
  font-size: 30px;
}
</style>
