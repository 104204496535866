export default class ProfileChunk {
    constructor(profileId, chunkId) {
        this.id = null
        this.profile_id = profileId
        this.chunk_id = chunkId
        this.title = null
        this.content = null
        this.media_url = null
    }
}
