<template>
    <v-tooltip bottom>
        <template #activator="{ on }">
            <v-btn
                fab 
                :x-small="xSmall"
                :small="small"
                class="ma-1"
                :color="color"
                :height="height"
                :width="width"
                :disabled="disabled"
                v-on="{...$listeners, ...on}"
            >
                <v-icon>{{ icon }}</v-icon>
            </v-btn>
        </template>
        {{ tooltipText }}
    </v-tooltip>
</template>

<script>
export default {

    name: 'ActionButton',

    props: {
        icon: {
            type: String,
            required: true
        },
        tooltipText: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: 'primary'
        },
        xSmall: {
            type: Boolean,
            default: true
        },
        small: Boolean,
        disabled: Boolean,
        height: {
            type: Number,
            default: null
        },
        width: {
            type: Number,
            default: null
        }
    }

}
</script>
