import axios from 'axios';

export const hosts = {
    namespaced: true,
    state: {
        loaders: {
            hosts: false,
            detail: false,
        },
        filter: {},
        dialogs: {
            hosts: false,
            detail: false,
            users: false,
        },
        users: [],
        hosts: [],
        selected_host: null
    },
    actions: {
        async loadHosts({commit}) {
            try {
                commit('setLoadersHost', true);
                const {data} = await axios.get('/api/host/all');
                commit('setHosts', data)
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersHost', false)
            }
        },
        async saveHost({commit, dispatch, state}, payload) {
            try {
                commit('setLoadersDetail', true);
                await axios.post('/api/host/save', payload);
                dispatch('loadHosts');
                commit('setDialogsDetail', false);
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersDetail', false)
            }
        },
    },
    mutations: {
        //setters
        setHosts(state, payload) {
            state.hosts = payload;
        },
        setSelectedHost(state, host) {
            state.selected_host = host;
        },


        //dialogs
        setDialogsDetail(state, visible) {
            state.dialogs.detail = visible
        },
        setDialogsUsers(state, visible) {
            state.dialogs.users = visible
        },


        //loaders
        setLoadersHost(state, visible) {
            state.loaders.hosts = visible
        },
        setLoadersDetail(state, visible) {
            state.loaders.detail = visible
        },
    },
    getters: {},
};
