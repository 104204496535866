import axios from 'axios'
import Project from '@/types/Project'
import Vue from 'vue'
import ProfileChunk from '@/types/ProfileChunk'
import PartitionProfile from '@/types/PartitionProfile'

const PROJECT_SAVE_TIMEOUT_AMOUNT = 3000
const TOAST_NOTIFICATION_TIMEOUT_AMOUNT = 6000

export const project = {
  namespaced: true,
  state: {
    projects: [],
    projects_active: [],
    projects_inactive: [],
    projectData: {},
    projectUsers: [],
    projectTranslationsDetail: {},
    projectTranslations: [],
    users: [],
    dialog: {
      detail: false,
      translation: false,
      projectUser: false,
      translationDetail: false,
      userDetail: false,
      timetable: false,
    },
    loaders: {
      newUser: false,
      project: false,
      isLoadingProject: false
    },
    host_id: 1,

  },
  actions: {

    async loadProjects({commit, dispatch}) {
      try {
        const {data} = await axios.get('/api/project/all')
        commit('setProjects', data)
      } catch (err) {
        console.log(err)
      } finally {
        dispatch('loadUsers')
      }
    },

    async loadProject({commit, dispatch}, payload) {
      try {
        commit('setLoadersIsLoadingProject', true)
        const {data} = await axios.get('/api/project/' + payload)
        commit('setProjectData', data)
      } catch (err) {
        dispatch('handleError', {text: 'Fehler beim Laden des Projekts'}, {root: true})
      } finally {
        commit('setLoadersIsLoadingProject', false)
      }
    },





    async loadUsers({commit, state}) {
      try {
        const {data} = await axios.get('/api/host/' + state.host_id + '/users')
        commit('setUsers', data)
      } catch (err) {
        console.error(err)
      }
    },

    async loadProjectUsers({state, commit}) {
      try {
        commit('setLoadersProject', true)
        const {data} = await axios.get('/api/project/' + state.projectData.id + '/user')
        console.info('loadProjectUsers', data)
        commit('setProjectData', data)
        commit('setProjectUsers', data.projectusers)
        commit('setProjectTranslationData', data.projectTranslations)
      } catch (err) {
        console.log(err)
      } finally {
        commit('setLoadersProject', false)
      }
    },

    async updateVisibility({dispatch, commit}, payload) {
      try {
        await axios.post('api/project/updateVisibility', {
          id: payload.id,
          active: payload.value
        })
        dispatch('loadProjects')
      } catch (err) {
        console.error(err)
      }
    },

    async updateCreateProject({dispatch, commit}, payload) {
      try {
        commit('setLoadersProject', true)
        Vue.prototype.$toast('Änderungen werden gespeichert!', {
          type: 'info',
          top: true,
          right: true,
          timeout: 2000,
        })
        await axios.post('api/project/updatecreate', payload)
        dispatch('loadProjects')
        commit('setDialogProjectDetail', false)
        commit('setDialogProjectTranslation', false)
        Vue.prototype.$toast('Projekt gespeichert!', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
      } catch (err) {
        dispatch('handleError', {text: 'Projekt konnte nicht gespeichert werden'}, {root: true})
      } finally {
        commit('setLoadersProject', false)
      }
    },

    async createUpdateProject({dispatch, commit}, payload) {
      alert('store create')
      return 0
      // try {
      //   commit('setLoadersProject', true)
      //   Vue.prototype.$toast('Änderungen werden gespeichert!', {
      //     type: 'info',
      //     top: true,
      //     right: true,
      //     timeout: 2000,
      //   })
      //   await axios.post('api/content/update/' + payload.id, payload)
      //   dispatch('loadProjects')
      //   commit('setDialogProjectDetail', false)
      //   commit('setDialogProjectTranslation', false)
      //   Vue.prototype.$toast('Projekt gespeichert!', {
      //     type: 'success',
      //     top: true,
      //     right: true,
      //     timeout: 3000,
      //   })
      // } catch (err) {
      //   dispatch('handleError', {text: 'Projekt konnte nicht gespeichert werden'}, {root: true})
      // } finally {
      //   commit('setLoadersProject', false)
      // }
    },

    async updateProject({dispatch, commit}, payload) {
      alert('store update project')
      return 0
      // try {
      //   commit('setLoadersProject', true)
      //   Vue.prototype.$toast('Änderungen werden gespeichert!', {
      //     type: 'info',
      //     top: true,
      //     right: true,
      //     timeout: 2000,
      //   })
      //   await axios.post(`api/content/update/${payload.id}`, payload)
      //   dispatch('loadProjects')
      //   commit('setDialogProjectDetail', false)
      //   commit('setDialogProjectTranslation', false)
      //   Vue.prototype.$toast('Projekt gespeichert!', {
      //     type: 'success',
      //     top: true,
      //     right: true,
      //     timeout: 3000,
      //   })
      // } catch (err) {
      //   dispatch('handleError', {text: 'Projekt konnte nicht gespeichert werden'}, {root: true})
      // } finally {
      //   commit('setLoadersProject', false)
      // }
    },

    createUpdateProjectTranslations({dispatch, state}, payload) {
      state.projectData.projectTranslations = payload
      dispatch('createUpdateProject', state.projectData)
    },

    async saveUserToProject({dispatch, state, commit}, payload) {
      try {
        commit('setLoadersNewUser', true)
        await axios.post('/api/project/' + state.projectData.id + '/addUser', {
          id: payload
        })
        commit('setDialogUserDetail', false)
        dispatch('loadProjectUsers')
        Vue.prototype.$toast('user added', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
      } catch (err) {
        console.error(err)
      } finally {
        commit('setLoadersNewUser', false)
      }
    },

    async deleteUserFromProject({dispatch, state, commit}, payload) {
      try {
        commit('setLoadersProject', true)
        await axios.post('/api/project/' + state.projectData.id + '/removeUser', payload)
        Vue.prototype.$toast('user deleted', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        dispatch('loadProjectUsers')
      } catch (err) {
        console.error(err)
      }
    },

    async addTranslationToProject({state, commit, dispatch}, payload) {

      try {
        commit('setLoadersNewTranslation', true)
        await axios.post('/api/project/' + state.projectData.id + '/addTranslation', payload)
        Vue.prototype.$toast('translation added', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        commit('setDialogsTranslationDetail', false)
        dispatch('loadProjectUsers')
      } catch (err) {
        console.error(err)
      } finally {
        commit('setLoadersNewTranslation', false)
      }
    },

    async deleteTranslationFromProject({state, commit, dispatch}, payload) {
      try {
        commit('setLoadersNewTranslation', true)
        await axios.post('/api/project/' + state.projectData.id + '/removeTranslation', payload)
        Vue.prototype.$toast('translation deleted', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        commit('setDialogsTranslationDetail', false)
        dispatch('loadProjectUsers')
      } catch (err) {
        console.error(err)
      } finally {
        commit('setLoadersNewTranslation', false)
      }
    },

    async updateTranslationFromProject({state, commit, dispatch}, payload) {
      try {
        commit('setLoadersNewTranslation', true)
        await axios.post('/api/project/' + state.projectData.id + '/updateTranslation', payload)
        Vue.prototype.$toast('translation updated', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        commit('setDialogsTranslationDetail', false)
        dispatch('loadProjectUsers')
      } catch (err) {
        console.error(err)
      } finally {
        commit('setLoadersNewTranslation', false)
      }
    },

    async saveTimetable({state, commit, dispatch}, payload) {
      try {
        await axios.post('/api/project/' + state.projectData.id + '/saveTimetable', payload)
        Vue.prototype.$toast('timetable saved', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        dispatch('loadProjectUsers')
      } catch (err) {
        console.error(err)
      }
    },

    async createNewEvent({state, commit, dispatch}, payload) {
      try {
        await axios.post('/api/project/' + payload.project_id + '/createEvent', payload)

        dispatch('loadProject', payload.project_id)
        dispatch('loadProjectUsers')
        dispatch('admin/loadCalendarEntries', null, {root: true})
      } catch (err) {
        console.error(err)
      }
    },
    async updateEvent({state, commit, dispatch}, payload) {
      console.log(payload)
      try {
        await axios.post('/api/project/' + payload.project_id + '/updateEvent', payload)
        Vue.prototype.$toast('Event updated', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        dispatch('loadProject', payload.project_id)
        dispatch('loadProjectUsers')
        dispatch('admin/loadCalendarEntries', null, {root: true})
      } catch (err) {
        console.error(err)
      }
    },

    async addPartition({commit, dispatch}, {previousPartitionSeq, project_id}) {
      try {
        const result = await axios.post('api/content/addPartition', {previousPartitionSeq, project_id})
        const newPartition = result.data
        commit('addPartition', newPartition)
        return newPartition
      } catch (err) {
        dispatch('handleError', {text: 'Fehler beim Erstellen einer Partition'}, {root: true})
      }
    },

    async deletePartition({commit, dispatch}, partition) {
      let deleteTimeout = null
      commit('deletePartition', partition)
      Vue.prototype.$toast('Partition gelöscht', {
        type: 'success',
        top: true,
        right: true,
        timeout: TOAST_NOTIFICATION_TIMEOUT_AMOUNT,
        actionButton: {
          text: 'Rückgängig',
          icon: 'mdi-refresh',
          onClick: () => {
            commit('addPartition', partition)
            clearTimeout(deleteTimeout)
          },
          closeOnClick: true
        }
      })
      deleteTimeout = setTimeout(async () => {
        try {
          const result = await axios.post(`api/content/deletePartition/${partition.id}`)
          if (result.data === false) {
            throw Error('Failed to delete partition')
          }
        } catch (err) {
          dispatch('handleError', {text: 'Fehler beim Löschen der Partition'}, {root: true})
        }
      }, TOAST_NOTIFICATION_TIMEOUT_AMOUNT)
    },

    async addChunk({commit, dispatch}, {previousChunkSeq, project_id, partition_id}) {
      try {
        const result = await axios.post('api/content/addChunk', {previousChunkSeq, project_id, partition_id})
        const newChunk = result.data
        commit('addChunk', newChunk)
        return newChunk
      } catch (err) {
        dispatch('handleError', {text: 'Fehler beim Hinzufügen des Chunks'}, {root: true})

      }
    },

    async deleteChunk({commit, dispatch}, {chunk}) {
      let deleteTimeout = null
      commit('deleteChunk', chunk)
      Vue.prototype.$toast('Chunk gelöscht', {
        type: 'success',
        top: true,
        right: true,
        timeout: TOAST_NOTIFICATION_TIMEOUT_AMOUNT,
        actionButton: {
          text: 'Rückgängig',
          icon: 'mdi-refresh',
          onClick: () => {
            commit('addChunk', chunk)
            clearTimeout(deleteTimeout)
          },
          closeOnClick: true
        }
      })
      deleteTimeout = setTimeout(async () => {
        try {
          const result = await axios.post(`api/content/deleteChunk/${chunk.id}`)
          if (result.data === false) {
            throw Error('Failed to delete chunk')
          }
        } catch (err) {
          dispatch('handleError', {text: 'Fehler beim Löschen des Chunks'}, {root: true})
        }
      }, TOAST_NOTIFICATION_TIMEOUT_AMOUNT)
    },

    async deleteProfileFromProject({commit, dispatch}, {profile}) {
      try {
        const result = await axios.post(`api/content/deleteProfile/${profile.id}`)
        console.log('toppest kek', result)
        if (result.data === false) {
          throw Error('Failed to delete profile')
        }
        commit('deleteProfileFromProject', profile)
      } catch (err) {
        dispatch('handleError', {text: 'Fehler beim Löschen des Profils'}, {root: true})
      }
    },

    // used by some input components of the content manager for auto-saving:
    saveProjectAfterTimeout({state, dispatch}) {
      if (state.projectSaveTimeout) { // reset the timeout
        clearTimeout(state.projectSaveTimeout)
      }
      state.projectSaveTimeout = setTimeout(() => dispatch('updateProject', state.projectData), PROJECT_SAVE_TIMEOUT_AMOUNT)
    },

  },
  mutations: {

    //Setters
    setUsers(state, users) {
      state.users = users
    },
    setProjectData(state, payload) {
      state.projectData = payload
    },
    setProjects(state, payload) {
      state.projects = payload.map((data) => Object.assign(new Project(), data))
      state.projects_active = state.projects.filter((pr) => pr.active)
      state.projects_inactive = state.projects.filter((pr) => !pr.active)
    },
    setProjectUsers(state, payload) {
      state.projectUsers = payload
    },
    setProjectTranslationData(state, payload) {
      state.projectTranslations = payload
    },
    setTranslationDetail(state, payload) {
      state.projectTranslationsDetail = payload
    },

    //Dialogs
    setDialogProjectDetail(state, payload) {
      state.dialog.detail = payload
    },
    setDialogProjectTranslation(state, visible) {
      state.dialog.translation = visible
    },
    setDialogsProjectUser(state, visible) {
      state.dialog.projectUser = visible
    },
    setDialogsTranslationDetail(state, visible) {
      state.dialog.translationDetail = visible
    },
    setDialogUserDetail(state, visible) {
      state.dialog.userDetail = visible
    },
    setDialogTimetable(state, visible) {
      state.dialog.timetable = visible
    },

    //Loaders
    setLoadersNewUser(state, visible) {
      state.loaders.newUser = visible
    },

    setLoadersProject(state, visible) {
      state.loaders.project = visible
    },

    setLoadersIsLoadingProject(state, visible) {
      state.loaders.isLoadingProject = visible
    },

    setLoadersNewTranslation(state, visible) {
      state.loaders.projectTranslation = visible
    },

    addPartition(state, newPartition) {
      state.projectData.partitions.forEach(partition => {
        if (partition.seq_number >= newPartition.seq_number) {
          ++partition.seq_number
        }
      })
      state.projectData.partitions.push(newPartition)
    },

    deletePartition(state, partition) {
      state.projectData.partitions = state.projectData.partitions.filter(part => {
        if (part.seq_number > partition.seq_number) {
          --part.seq_number
        }
        return part !== partition
      })
    },

    addChunk(state, newChunk) {
      const partition = state.projectData.partitions.find(partition => partition.id === newChunk.partition_id)
      partition.chunks.forEach(chunk => {
        if (chunk.seq_number >= newChunk.seq_number) {
          ++chunk.seq_number
        }
      })
      partition.chunks.push(newChunk)
    },

    deleteChunk(state, chunk) {
      const partition = state.projectData.partitions.find(partition => partition.id === chunk.partition_id)
      partition.chunks = partition.chunks.filter(otherChunk => {
        if (otherChunk.seq_number > chunk.seq_number) {
          --otherChunk.seq_number
        }
        return otherChunk !== chunk
      })
    },

    addOrUpdateProfile(state, payload) {
      const oldProfile = state.projectData.profiles.find(prof => prof.id === payload.id)
      if (oldProfile) {
        Object.assign(oldProfile, payload)
      } else {
        state.projectData.profiles.push(payload)
        state.projectData.partitions.forEach(partition => {
          partition.chunks.forEach(chunk => {
            chunk.profileChunks.push(new ProfileChunk(payload.id, chunk.id))
          })
          partition.partitionProfiles.push(new PartitionProfile(partition.id, payload.id))
        })
      }
    },

    deleteProfileFromProject(state, payload) {
      const oldProfileIndex = state.projectData.profiles.findIndex(prof => prof === payload)
      if (oldProfileIndex > -1) {
        state.projectData.profiles.splice(oldProfileIndex, 1)
        state.projectData.partitions.forEach(partition => {
          partition.chunks.forEach(chunk => {
            const oldProfileChunkIndex = chunk.profileChunks.findIndex(profChunk => profChunk.profile_id === payload.id)
            chunk.profileChunks.splice(oldProfileChunkIndex, 1)
          })

          partition.partitionProfiles.splice(partition.partitionProfiles.findIndex(p => p.profil_id === payload.id), 1)
        })
      }
    },

  },
  getters: {},
}
