<template>
  <v-dialog
    :value="true"
    width="25vw"
    persistent
  >
    <v-toolbar dense>
      <h3> {{ title }} </h3>
    </v-toolbar>
    <v-card
      width="100%"
      class="pa-4"
    >
      <v-form
        ref="profileForm"
        @submit.prevent="onClickSave()"
      >
        <v-text-field
          v-model="profile.title"
          label="Profilname"
          :rules="profileTitleRules"
        />
        <v-autocomplete
          v-model="profile.language_id"
          :items="languages"
          item-value="id"
          item-text="description"
        ></v-autocomplete>
      </v-form>
      <v-row
        justify="end"
        class=" pt-4 pr-2"
      >
        <v-btn
          small
          text
          class="mr-2"
          @click="onClickCancel()"
        >
          Abbrechen
        </v-btn>
        <v-btn
          small
          color="success"
          @click="onClickSave()"
        >
          Speichern
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import contentmanagerService from '@/services/Contentmanager.service'

export default {
  name: 'ProfileDialog',
  props: {
    value: Boolean,
    profile: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: 'create',
      validator: (mode) => ['create', 'read', 'edit'].includes(mode)
    }
  },

  data() {
    return {
      initialProfileTitle: '',
      profileTitleRules: [
        title => (title != null && title !== '') || 'Profilname muss angegeben werden',
      ]
    }
  },

  computed: {
    ...mapState('globals', ['languages']),
    ...mapState('project', ['projectData']),

    title() {
      switch (this.mode) {
      case 'create':
        return 'Neues Profil erstellen'
      case 'edit':
        return `Profil ${this.initialProfileTitle} bearbeiten`
      default:
        return 'Profil'
      }
    }
  },

  mounted() {
    this.initialProfileTitle = this.profile.title
    this.loadLanguages()
  },

  methods: {
    ...mapActions('project', ['addOrUpdateProfile']),
    ...mapActions('globals', ['loadLanguages']),

    async onClickSave() {
      if (this.$refs.profileForm.validate()) {
        if (this.mode === 'create') {
          try {
            await contentmanagerService.createProfile({
              project_id: this.profile.projectId,
              title: this.profile.title,
              language_id: this.profile.language_id
            })
            this.$toast('profile created', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
            this.$emit('close');
          } catch (e) {
            this.$toast('create error', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        } else if (this.mode === 'edit') {
          try {
            await contentmanagerService.updateProfile(this.profile)
            this.$toast('profile updated', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
            this.$emit('close');
          } catch (e) {
            this.$toast('update error', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }
        }
      }
    },

    onClickCancel() {
      this.$emit('close')
    }

  },
}
</script>

