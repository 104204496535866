<template>
  <v-card
      :id="`chunk_${chunk.id}`"
      ref="card"
      width="98.6%"
      class="ma-2 pt-1 pb-1 chunk-card"
      color="chunk darken-3"
  >
    <v-row align="center">
      <div style="width: 25%">
        <v-text-field
            v-model="chunk.title"
            placeholder="Titel des Chunks eingeben..."
            outlined
            hide-details
            dense
            class="chunk-title-text-field"
            @input="saveProjectAfterTimeout()"
        >
          <template #prepend-inner>
            <v-avatar
                size="25"
                color="chunk"
            >
              <span color="white">
                  {{ chunk.seq_number + 1 }}
              </span>
            </v-avatar>
          </template>
        </v-text-field>
      </div>
      <action-button
          color="chunk"
          icon="mdi-table-row-plus-after"
          tooltip-text="Neuen Chunk nach diesem Chunk einfügen"
          :height="25"
          :width="25"
          @click="createChunkAfter(chunk.seq_number)"
      />

      <action-button
          color="chunk"
          icon="mdi-image-edit"
          tooltip-text="Medieninhalt für gesamten Chunk bearbeiten"
          :height="25"
          :width="25"
          @click="onClickEditMedia()"
      />

      <action-button
          color="delete"
          icon="mdi-delete"
          tooltip-text="Chunk löschen"
          :height="25"
          :width="25"
          @click="confirmDeleteChunk()"
      />
    </v-row>
    <v-row>
      <v-slide-group
          show-arrows
          :class="{'ml-5' : chunk.profileChunks.length <= 5}"
      >
        <v-slide-item
            v-for="profileChunk in chunk.profileChunks"
            :key="profileChunk.id"
        >
          <!-- =================== COLUMN FOR A PROFILE (LANGUAGE) =================== -->
          <profile-chunk-editor
              :profile-chunk="profileChunk"
              :project-data="projectData"
              width="210px"
              @click-edit-media="onClickEditMedia(profileChunk)"
              @save-project="saveProjectAfterTimeout"
          />
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <chunk-media-dialog
        v-if="showChunkMediaDialog"
        :chunk="chunk"
        :profile-chunk="profileChunkForMediaDialog"
        :project-data="projectData"
        @save-project="saveProjectAfterTimeout"
        @close="showChunkMediaDialog = false"
    />
  </v-card>
</template>

<script>
import Chunk from '@/types/Chunk'
import ChunkMediaDialog from '@/components/ContentManager/ChunkMediaDialog'
import ProfileChunkEditor from '@/components/ContentManager/ProfileChunkEditor'
import {mapState, mapActions} from 'vuex'
import Vue from 'vue'
import contentmanagerService from '@/services/Contentmanager.service'

export default {
  name: 'ChunkCard',
  components: {
    ProfileChunkEditor,
    ChunkMediaDialog
  },
  props: {
    chunk: {
      type: Object,
      default: null
    },
    projectData: {
      type: Object,
      default: Object
    }
  },

  data() {
    return {
      profileChunkForMediaDialog: null,
      showChunkMediaDialog: false
    }
  },

  computed: {
    partition() {
      return this.projectData.partitions.find(partition => partition.id === this.chunk.partition_id)
    },

    profiles() {
      return null
    },
  },

  methods: {
    saveProjectAfterTimeout() {
      this.$emit('save-project')
    },

    async silentSave() {
      try {
        await this.$emit('silent-save')
      } catch (e) {
        Promise.reject(e)
      }
    },

    async createChunkAfter(position) {
      try {
        await this.silentSave()
        const newChunk = await contentmanagerService.createChunk(position, this.projectData.id, this.partition.id)
        this.$toast('Chunk erstellt', {
          type: 'success',
          top: true,
          right: true,
          timeout: 3000,
        })
        this.$emit('create-new-chunk', newChunk)
        this.$emit('reload')
      } catch (e) {
        this.$toast('Chunk fehler', {
          type: 'error',
          top: true,
          right: true,
          timeout: 3000,
        })
      }
    },

    onClickEditMedia(profileChunk) {
      // if profileChunk is null, then the media is to be added to all profileChunks of this chunk
      this.profileChunkForMediaDialog = profileChunk
      this.showChunkMediaDialog = true
    },

    openChunkMediaDialog() {
      this.showChunkMediaDialog = true
    },

    async animateBackground() {
      await this.$vuetify.goTo(this.$refs.card)
      this.$refs.card.$el.classList.toggle('chunk-card-animated')
      setTimeout(() => {
        this.$refs.card.$el.classList.toggle('chunk-card-animated')
      }, 1200)
    },

    async confirmDeleteChunk() {

      if (this.partition.chunks.length === 1) {
        this.$confirm(
            'Dieser Chunk kann nicht gelöscht werden, da es der einzige Chunk dieser Partition ist',
            {
              type: 'warning',
              noCancelButton: true
            }
        )
      } else {
        const answer = await this.$confirm(
            `Möchten Sie wirklich Chunk ${this.chunk.seq_number + 1} ${this.chunk.title ? '"' + this.chunk.title + '"' : ''} löschen?`,
            {
              type: 'delete',
              okText: 'Löschen',
              cancelText: 'Chunk beibehalten',
              showCountdown: true,
              countdowntimer: 5
            }
        )
        if (answer) {
          try {
            console.log('start deleting')
            await this.silentSave()
            console.log('after silentSave')
            await contentmanagerService.deleteChunk(this.chunk.id)
            this.$toast('Chunk gelöscht', {
              type: 'success',
              top: true,
              right: true,
              timeout: 3000,
            })
            this.$emit('reload')
          } catch (err) {
            Vue.prototype.$toast('Chunk deletion error', {
              type: 'error',
              top: true,
              right: true,
              timeout: 3000,
            })
          }

        }
      }
    }
  }
}
</script>

<style scoped>
.chunk-title-text-field {
  transform: scale(0.85);
}

.slide-group >>> .v-slide-group__content {
  overflow-y: visible !important;
}

.slide-group >>> .v-slide-group__prev,
.slide-group >>> .v-slide-group__next {
  width: 10px !important;
  min-width: 0 !important;
}

.chunk-card >>> .v-slide-group__wrapper {
  overflow: visible;
  overflow-x: hidden;
  overflow-y: visible !important;
}

.chunk-card-animated {
  animation: blink 0.4s infinite;
}

@keyframes blink {
  0% {
    background: #001200;
  }
  50% {
    background: green;
  }

  100% {
    background: #001200;
  }
}
</style>
