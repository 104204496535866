import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        dark: true,
        themes: {
            light: {
                primary: '#212121',
                secondary: '#1d87e5',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
                grey: '#e0e0e0',
            },
            dark: {
                primary: colors.blueGrey.darken1, // default color for input components
                secondary: colors.blueGrey.darken3,
                anchor: colors.blue.lighten1,
                error: colors.red.darken2,
                info: colors.indigo.darken2,
                success: colors.green.darken2,
                warning: colors.amber.darken2,
                delete: colors.red.darken2,
                partition: colors.cyan.darken3,
                chunk: colors.teal.darken3,
                card: colors.grey.darken3

            }
        },
    },
});
