import axios from 'axios';
import User from '../types/User';
import Vue from 'vue';
import {getField, updateField} from 'vuex-map-fields'

export const admin = {
    namespaced: true,
    state: {
        loaders: {
            users: false,
            save: false,
            hostdetail: false,
            calendarEntries: false,
        },
        filter: {},
        dialogs: {
            userdetail: false,
            userRole: false
        },
        users: [],
        host_id: 1,
        selected_user: null,
        hostdetail: {},
        calendarEntries: [],
        itemheight: 2
    },

    actions: {
        async loadUsers({commit, state}) {
            try {
                commit('setLoadersUsers', true);
                const {data} = await axios.get('/api/host/' + state.host_id + '/users');
                commit('setUsers', data)
            } catch (err) {
                console.log(err);
            } finally {
                commit('setLoadersUsers', false)
            }
        },

        async loadUser({commit, dispatch, state}) {
            try {
                const {data} = await axios.get('/api/host/' + state.host_id + '/user/' + state.selected_user.id);
                commit('setSelectedUser', data);
            } catch (err) {
                console.log(err);
            } finally {
                dispatch('loadUsers');
            }
        },

        async loadHostdetails({commit, state}) {
            try {
                commit('setLoadersHostdetail', true);
                const {data} = await axios.get('/api/host/' + state.host_id);
                commit('setHostDetail', data);
            } catch (err) {
                console.log(err);
            } finally {
                commit('setLoadersHostdetail', false)
            }
        },

        async updateHostdetails({commit, dispatch, state}, payload) {
            try {
                const {data} = await axios.post('/api/host/' + state.host_id + '/update', payload);
                commit('setHostDetail', data)
            } catch (err) {
                console.log(err);
            }
        },

        async saveUser({commit, dispatch, state}, payload) {
            try {
                commit('setLoadersSave', true);
                await axios.post('/api/host/' + state.host_id + '/user/register', payload);
                commit('setDialogUserDetail', false);
                dispatch('loadUsers')
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersSave', false)
            }
        },

        async updateUser({commit, dispatch, state}, payload) {
            try {
                commit('setLoadersSave', true);
                await axios.post('/api/host/' + state.host_id + '/user', payload);
                commit('setDialogUserDetail', false);
                dispatch('loadUsers')
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersSave', false)
            }
        },

        async addRoleToUser({commit, dispatch, state}, payload) {
            try {
                commit('setLoadersSave', true);
                await axios.post('/api/host/' + state.host_id +
                    '/user/' + state.selected_user.id +
                    '/addRole', payload);
                commit('setDialogsUserRole', false);
                dispatch('loadUser');
                dispatch('loadUsers');
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersSave', false)
            }
        },

        async removeRoleFromUser({commit, dispatch, state}, payload) {
            try {
                commit('setLoadersSave', true);
                await axios.post('/api/host/' + state.host_id +
                    '/user/' + state.selected_user.id +
                    '/removeRole', payload);
                dispatch('loadUsers');
                dispatch('loadUser')
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersSave', false)
            }
        },

        async generateNewPin({dispatch, state}) {
            try {
                const {data} = await axios.get('/api/host/' + state.host_id + '/newPin');
                dispatch('loadHostdetails');

                Vue.prototype.$toast('New Pin: ' + data, {
                    type: 'success',
                    timer: '3000',
                })
            } catch (err) {
                console.error(err);
            }
        },

        async loadCalendarEntries({commit, state}) {
            try {
                commit('setLoadersCalendarEntries', true);
                const {data} = await axios.get('/api/host/' + state.host_id + '/calendarEntries');
                commit('setCalendarEntries', data)
            } catch (err) {
                console.error(err);
            } finally {
                commit('setLoadersCalendarEntries', false)
            }
        },
    },
    mutations: {

        //setters
        setUsers(state, users) {
            state.users = users.map((data) => Object.assign(new User(), data));
        },
        setSelectedUser(state, user) {
            state.selected_user = user
        },
        setHostId(state, id) {
            state.host_id = id;
        },
        setHostDetail(state, payload) {
            state.hostdetail = payload;
        },
        setCalendarEntries(state, payload) {
            state.calendarEntries = payload;
        },

        //dialogs
        setDialogUserDetail(state, visible) {
            state.dialogs.userdetail = visible
        },
        setDialogsUserRole(state, visible) {
            state.dialogs.userRole = visible;
        },

        //loaders
        setLoadersUsers(state, visible) {
            state.loaders.users = visible
        },
        setLoadersSave(state, visible) {
            state.loaders.save = visible
        },
        setLoadersHostdetail(state, visible) {
            state.loaders.hostdetail = visible;
        },
        setLoadersCalendarEntries(state, visible) {
            state.loaders.calendarEntries = visible;
        },

        // vuex-map-fields hook
        updateField
    },
    getters: {
        // vuex-map-fields hook
        getField
    },
};
